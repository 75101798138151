
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  defineAsyncComponent,
} from "vue";
import { useRouter } from "vue-router";
import { loginRequest } from "@/utils/types";
import { login } from "@/services/auth";

export default defineComponent({
  components: {
    Alert: defineAsyncComponent(() => import("@/components/Alert.vue")),
  },
  name: "Index",
  setup() {
    // data properties
    const formData: loginRequest = reactive({ email: "", password: "" });
    const isDisabled = ref<boolean>(false);
    const isError = ref<boolean>(false);
    const type = ref<string>("");
    const message = ref<string>("");

    const router = useRouter();
    const handleSubmitRequest = async (): Promise<void> => {
      try {
        isDisabled.value = true;
        const { data } = await login(formData);
        localStorage.setItem("authToken", data.data.access_token);
        await router.push({ name: "dashboard" });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        isError.value = true;
        type.value = "danger";
        if (e.response) {
          message.value = e.response?.data?.message;
        }
      } finally {
        isDisabled.value = false;
      }
    };
    return {
      handleSubmitRequest,
      ...toRefs(formData),
      isDisabled,
      type,
      message,
      isError,
    };
  },
});
