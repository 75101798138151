import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";

import router from "@/router/index";

const instance: AxiosInstance = axios.create();

instance.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PROD_API_URL
    : process.env.VUE_APP_DEV_API_URL;

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      config.headers!["Authorization"] = `Bearer ${token}`;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    config.headers!["Content-Type"] = "application/json";
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("authToken");
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.push({ name: "login" }).catch(() => {});
    }
    return Promise.reject(error);
  }
);

export default instance;
