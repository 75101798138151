import axios from "./axios";
const endpoint = "/auth";
import { Response, loginResponse, loginRequest } from "@/utils/types";
import { AxiosResponse } from "axios";
const login = (
  formData: loginRequest
): Promise<AxiosResponse<Response<loginResponse>>> => {
  return axios.post(`${endpoint}/login`, formData);
};

const logOut = (): Promise<AxiosResponse> => {
  return axios.post(`${endpoint}/logout`);
};
export { login, logOut };
