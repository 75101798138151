import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/auth/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/",
    name: "main",
    component: () => import("@/components/layout/Main.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/Index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "admissions/preset",
        name: "admission-preset",
        component: () => import("@/views/admissions/Preset.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "admissions/premodal",
        name: "admission-premodal",
        component: () => import("@/views/admissions/Premodal.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//protect route
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("authToken");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      next();
    } else {
      next({
        name: "login",
      });
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (token) {
      next({
        name: "dashboard",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
